<template>
    <div class="view" ref="yqContent" @mouseup="mouseupZoom" @mousemove="moveZoom">
        <div class="d-flex yqContent">
            <div class="leftArea" :style="'width:' + areaWidth + 'px'">
                <!-- <div class="d-flex">
                    <div>
                        <el-input class="w200 mb10 mr10" placeholder="请输入地区关键字" v-model="searchAreaName" />
                        <el-button type="primary" @click="getLeftAreaData">搜索</el-button>
                    </div>
                </div> -->
                <div class="mt20 mb20 ml20">
                    <el-tree :props="props" :load="loadAreaFun" :data="leftAreaData" v-loading="loadingArea" lazy
                        :expand-on-click-node="false" @node-click="changeArea">
                    </el-tree>
                </div>
            </div>
            <div class="zoom flex-c-c" @mousedown="mousedownZoom">
                <i class="el-icon-d-arrow-left"></i>
            </div>
            <!-- v-if="isMousedown"  -->
            <div class="zoom flex-c-c Illusion" v-if="isMousedown" :style="'left:' + zoomLeft + 'px'">
                <i class="el-icon-d-arrow-left"></i>
            </div>
            <div class="rightTable pa24" :style="'width:calc(100% - ' + areaWidth + 'px)'">
                <div class="d-flex">
                    <div style="margin-right:20px;">
                        <el-breadcrumb separator-class="el-icon-arrow-right" style="line-height:32px;">
                            <el-breadcrumb-item v-for="(name, index) of checkedAreaArr" :key="index">{{ name }}
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div>
                        <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                        <el-button type="primary" @click="searchFun">搜索</el-button>
                    </div>
                    <div style="margin-left: auto">
                        <el-button class="ma" type="primary" @click="showAddModel">新增园区</el-button>
                    </div>
                </div>
                <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                    @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
                    <template v-slot:table>
                        <el-table-column type="index" align="center" label="序号" />
                        <el-table-column prop="parkName" align="center" label="园区名称" show-overflow-tooltip />
                        <el-table-column prop="propertyName" align="center" label="物业" show-overflow-tooltip />
                        <el-table-column prop="linkman" align="center" label="姓名" show-overflow-tooltip />
                        <el-table-column prop="linkmanPhone" align="center" label="电话" show-overflow-tooltip />
                        <el-table-column prop="address" align="center" label="地址" show-overflow-tooltip />
                        <el-table-column show-overflow-tooltip prop="isTop" width="100" align="center" label="状态">
                            <template slot-scope="scope">
                                <el-tag type="success" v-if="scope.row.isTop == 1">置顶</el-tag>
                                <el-tag type="info" v-else>默认</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="操作" width="80">
                            <template slot-scope="scope">
                                <el-button class="" type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                                <!-- <el-button class="" type="text" @click="showEnterTypeModel(scope.row)">进驻分类</el-button> -->
                            </template>
                        </el-table-column>
                    </template>
                </commonTable>
            </div>
        </div>

        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" @close="closeFun" :destroy-on-close="true" width="80%" center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
                <el-form-item label="园区LOGO" prop="parkImg" required>
                    <el-upload :action="$store.state.uploadingUrl" list-type="picture-card"
                        :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="linkmanImgProgress"
                        :on-success="linkmanImgSuccess" :on-remove="linkmanImgRemove" :file-list="parkImg">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="h100p d-flex align-items-center">
                        <p>最多只能上传1张，建议上传750x750，格式可为png、jpg或jpeg</p>
                    </div>
                </el-form-item>
                <el-row>
                    <el-col :md="11" class="mr20">
                        <el-form-item label="园区名称" prop="parkName">
                            <el-input placeholder="请输入园区名称" v-model="ruleForm.parkName" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="园区产业" prop="industry">
                            <el-select class="w100p" v-model="ruleForm.industry" filterable placeholder="请选择园区产业">
                                <el-option v-for="item in industryData" :key="item.dictValue" :label="item.dictLabel"
                                    :value="item.dictValue">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="mr20">
                        <el-form-item label="园区面积" prop="parkArea">
                            <el-input type="number" class="suffixInput" placeholder="请输入园区面积"
                                v-model="ruleForm.parkArea" clearable>
                                <template slot="append">m²</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="建筑面积" prop="buildArea">
                            <el-input type="number" class="suffixInput" placeholder="请输入建筑面积"
                                v-model="ruleForm.buildArea" clearable>
                                <template slot="append">m²</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="mr20">
                        <el-form-item label="选择物业" prop="propertyId">
                            <el-select class="w100p" v-model="ruleForm.propertyId" filterable remote
                                :remote-method="getCompanyAllInfo" :loading="loadingProperty" placeholder="请选择物业">
                                <el-option v-for="item in propertyData" :key="item.companyId" :label="item.companyName"
                                    :value="item.companyId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="园区等级" prop="parkLevel">
                            <el-select class="w100p" v-model="ruleForm.parkLevel" filterable remote
                                placeholder="请选择园区等级">
                                <el-option v-for="item in parkLevelData" :key="item.dictValue" :label="item.dictLabel"
                                    :value="item.dictValue">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="mr20">
                        <el-form-item label="联系人" prop="linkman">
                            <el-input placeholder="请输入联系人" v-model="ruleForm.linkman" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="联系电话" prop="linkmanPhone">
                            <el-input placeholder="请输入联系电话" :maxlength="11" v-model="ruleForm.linkmanPhone" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="mr20">
                        <el-form-item label="联系QQ" prop="linkmanQq">
                            <el-input placeholder="请输入联系QQ" v-model="ruleForm.linkmanQq" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="联系微信" prop="linkmanWx">
                            <el-input placeholder="请输入联系微信" v-model="ruleForm.linkmanWx" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-form-item label="图文图片" prop="photos">
                    <el-upload :action="$store.state.uploadingUrl" list-type="picture-card"
                        :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="handProgress"
                        :on-success="handleSuccess" :on-remove="handleRemove" :file-list="fileList">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="h100p d-flex align-items-center">
                        <p>建议上传750x750，格式可为png、jpg或jpeg</p>
                    </div>
                </el-form-item>
                <el-form-item label="收费专用章" prop="photos">
                    <el-upload :action="$store.state.uploadingUrl" list-type="picture-card"
                        :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="handProgress"
                        :on-success="handleSuccess" :on-remove="handleRemove" :file-list="fileList">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="h100p d-flex align-items-center">
                        <p>建议上传750x750，格式可为png、jpg或jpeg</p>
                    </div>
                </el-form-item>
                <el-form-item label="二维码背景" prop="photos">
                    <el-upload :action="$store.state.uploadingUrl" list-type="picture-card"
                        :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="handProgress"
                        :on-success="handleSuccess" :on-remove="handleRemove" :file-list="fileList">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="h100p d-flex align-items-center">
                        <p>建议上传750x750，格式可为png、jpg或jpeg</p>
                    </div>
                </el-form-item> -->
                <el-form-item label="地区" prop="areaCode">
                    <el-select class="w130" v-model="province" placeholder="省" filterable
                        @change="getTbArea(1, $event)">
                        <el-option v-for="item in provinceData" :key="item.areaCode" :label="item.name"
                            :value="item.areaCode">
                        </el-option>
                    </el-select>
                    <el-select class="w130" v-model="city" placeholder="市" filterable @change="getTbArea(2, $event)">
                        <el-option v-for="item in cityData" :key="item.areaCode" :label="item.name"
                            :value="item.areaCode">
                        </el-option>
                    </el-select>
                    <el-select class="w130" v-model="area" placeholder="区/县" filterable @change="getTbArea(3, $event)">
                        <el-option v-for="item in areaData" :key="item.areaCode" :label="item.name"
                            :value="item.areaCode">
                        </el-option>
                    </el-select>
                    <el-select class="w130" v-model="ruleForm.areaCode" placeholder="镇/街道" filterable>
                        <el-option v-for="item in streetData" :key="item.areaCode" :label="item.name"
                            :value="item.areaCode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                    <el-input placeholder="请输入详细地址" v-model="ruleForm.address" clearable />
                </el-form-item>
                <el-row>
                    <el-col :span="13">
                        <el-form-item label="坐标" required>
                            <div class="d-flex">
                                <el-form-item prop="lng">
                                    <el-input class="w130 mr10" type="number" placeholder="地理经度" v-model="ruleForm.lng"
                                        clearable />
                                </el-form-item>
                                <el-form-item prop="lat">
                                    <el-input class="w130 mr10" type="number" placeholder="地理纬度" v-model="ruleForm.lat"
                                        clearable />
                                </el-form-item>
                                <el-button type="primary" style="height:32px" @click="showMapFun">选择坐标</el-button>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="排序" required>
                            <el-switch v-model="ruleForm.isTop" active-text="置顶" :active-value="1" inactive-text="默认"
                                :inactive-value="0">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="园区介绍" prop="parkIntroduce">
                    <quill-editor style="height: 100%" v-model="ruleForm.parkIntroduce" ref="myQuillEditor"
                        :options="editorOption" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">提 交</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择地址" width="80%"
            :visible.sync="showMap" :destroy-on-close="true">
            <map-model @lngLatChange="lngLatChange" :lng="ruleForm.lng" :lat="ruleForm.lat"></map-model>
            <!-- <div id="baidumap" style="width: 100%; height: 400px; margin-top: 10px"></div> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="showMap = false" type="primary">确 定</el-button>
            </span>
        </el-dialog>
        <!-- <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="parkTitle" :visible.sync="showEnterType" 
            :destroy-on-close="true">
            <el-tabs v-model="typeUse" @tab-click="getParkShopTypeLinkList()">
                <el-tab-pane label="周边店铺" name="1"></el-tab-pane>
                <el-tab-pane label="服务大厅" name="0"></el-tab-pane>
            </el-tabs>
            <commonTable :tableData="typeData" :loading="typeDataLoading" :paginationVisible="false">
                <template v-slot:table>
                    <el-table-column prop="iconUrl" label="分类图标" align="center" width="150">
                        <template slot-scope="scope">
                            <img :src="scope.row.iconUrl" width="50px" height="50px" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="typeName" align="center" label="分类名称" show-overflow-tooltip />
                    <el-table-column align="center" label="操作" width="150">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value="0" active-text="启用" inactive-text="关闭"
                                :inactive-value="1" @change="changeEnterTypeStatus($event,scope.row)">
                            </el-switch>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showEnterType = false" type="primary">确 定</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>

<script>
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写园区介绍'
var Align = Quill.import('attributors/style/align');
Align.whitelist = ['right', 'center', 'justify'];
Quill.register(Align, true);

import {
    getParkManageList,
    setParkManage,
    getTbArea,
    getParentByCode,
    getCompanyAllInfo,
    getParkManageAndTbArea,
    getByDictType,

    //进驻分类配置
    // getParkShopTypeLinkList,
    // insertParkShopTypeLink,
    // updateParkShopTypeLink,
} from "@/api/parkManagement"
import commonTable from "@/components/common/commonTable";
import mapModel from "@/components/common/mapModel";
// import { selectAllMemberLevel, setMemberLevel } from "@/api/member";
export default {
    name: "residentialQuartersTable",
    components: {
        commonTable,
        quillEditor,
        mapModel
    },
    data() {
        return {
            //左地区筛选配置
            props: {
                label: 'name',
                isLeaf: 'leaf'
            },
            zoomLeft: 150,
            areaWidth: 150,
            loadingArea: false,
            searchAreaName: "",
            leftAreaData: [],
            leftAreaDataAll: {},
            isMousedown: false,
            //新增/修改配置
            editorOption: quillConfig,
            provinceData: [],//省数据
            cityData: [],//市数据
            areaData: [],//区数据
            streetData: [],//街道数据
            propertyData: [],//物业数据
            parkLevelData: [],//园区等级
            industryData: [],//园区产业
            province: "",//省
            city: "",//市
            area: "",//区
            disabled: false,
            ruleForm: {
                companyId: this.$store.state.loginRoot.userInfo.companyId,
                propertyId: "",
                parkName: "",
                linkman: "",
                linkmanPhone: "",
                linkmanQq: "",
                linkmanWx: "",
                parkImg: "",
                parkIntroduce: "",
                areaCode: "",
                address: "",
                lng: 0,
                lat: 0,
                parkLevel: "",
                industry: "",
                buildArea: "",
                parkArea: "",
                isTop: 0,
            },
            parkImg: [],
            areaName: "",//地图查询值
            rules: {
                parkImg: [{ required: true, message: "请上传园区LOGO" }],
                parkName: [{ required: true, message: "请输入园区名称", trigger: "blur" }],
                parkArea: [{ required: true, message: "请输入园区面积", trigger: "blur" }],
                linkman: [{ required: true, message: "请输入联系人", trigger: "blur" }],
                linkmanPhone: [{ required: true, message: "请输入联系电话", trigger: "blur" }, { message: "手机号格式错误", pattern: this.$store.state.phoneReg }],
                areaCode: [{ required: true, message: "地区必须请选择到镇/街道", trigger: "change" }],
                address: [{ required: true, message: "请输入详情地址", trigger: "blur" }],
                lng: [{ required: true, message: '请输入经度', trigger: 'blur' }],
                lat: [{ required: true, message: '请选择纬度', trigger: 'blur' }],
            },
            modelTitle: "",//新增/编辑模态框标题
            formType: "",//新增/编辑
            updateId: 0,//编辑id
            map: {}, //赋值地图
            timeFun: null,
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "", areaCode: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            showMap: false,
            loadingProperty: false,
            checkedAreaArr: ["全部"],
            //腾讯地图配置
            txMap: {},
            txMapSearch: {},
            txMapSuggest: {},
            //关联进驻分类配置
            // showEnterType: false,
            // parkTitle: "",
            // typeUse: "1",
            // typeData: [],
            // typeDataLoading: false,
            // parkId: "",
        };
    },
    computed: {
        uploadDisabled() {
            return this.ruleForm.parkImg !== "";
        },
    },
    created() {
        this.getList();//获取列表
        this.getCompanyAllInfo();//获取物业数据
        this.getTbArea(0);//获取编辑地区数据
        this.getByDictType();
    },
    watch: {
        "formData.lng": function (val) {
            this.formData.lng = Number(val);
        },
        "formData.lat": function (val) {
            this.formData.lat = Number(val);
        }
    },
    methods: {
        // /**@method 开启/关闭进驻分类 */
        // async changeEnterTypeStatus(status, row) {
        //     let params = {
        //         status,
        //         typeId: row.typeId,
        //         parkId: this.parkId
        //     };
        //     let res = null;
        //     if (row.linkId) {
        //         params.linkId = row.linkId;
        //         res = await updateParkShopTypeLink(params)
        //     } else {
        //         res = await insertParkShopTypeLink(params)
        //     }
        //     if (res.code != 200) {
        //         this.$message({
        //             message: res.message,
        //             type: "error"
        //         })
        //     }
        // },
        // /**@method 获取分类数据 */
        // async getParkShopTypeLinkList() {
        //     this.typeDataLoading = true;
        //     let res = await getParkShopTypeLinkList({
        //         parkId: this.parkId,
        //         typeUse: this.typeUse
        //     });
        //     try {
        //         this.typeData = res.data;
        //     } catch (err) {
        //         this.typeData = [];
        //     }
        //     this.typeDataLoading = false;
        // },
        // /**@method 显示进驻分类列表 */
        // showEnterTypeModel(row) {
        //     this.parkId = row.parkId;
        //     this.parkTitle = row.parkName;
        //     this.showEnterType = true;
        //     this.getParkShopTypeLinkList();
        // },
        /**@method 获取字典 */
        getByDictType() {
            let that = this;
            //产业
            getByDictType({ dictType: "industry", status: 0 }).then(industryRes => {
                try {
                    that.industryData = industryRes.data.sysDictDataList;
                } catch (err) {
                    that.industryData = [];
                }
            })
            //等级
            getByDictType({ dictType: "park_level", status: 0 }).then(parkLevelRes => {
                try {
                    that.parkLevelData = parkLevelRes.data.sysDictDataList;
                } catch (err) {
                    that.parkLevelData = [];
                }
            })
        },
        /**@method 缩放移动 */
        moveZoom(e) {
            if (this.isMousedown) {
                let odiv = this.$refs.yqContent; //获取目标元素
                //算出鼠标相对元素的位置
                let disX = e.clientX - (window.innerWidth - (odiv.offsetWidth + 26));
                this.zoomLeft = disX
            }
        },
        /**@method 允许缩放 */
        mousedownZoom() {
            this.isMousedown = true;
        },
        /**@method 结束缩放 */
        mouseupZoom() {
            this.isMousedown = false;
            this.areaWidth = this.zoomLeft
        },
        /**@method 选中园区 */
        changeArea(data) {
            if (data.mergerName) {
                this.checkedAreaArr = data.mergerName.split(",")
            }
            this.searchData.areaCode = String(data.areaCode).replace(/(0*)$/g, "");
            this.currentPage = 1
            this.getList();
        },
        /**@method 获取有园区的地区 */
        async getParkManageAndTbArea() {
            this.loadingArea = true;
            let res = await getParkManageAndTbArea({ companyId: this.userInfo.companyId })
            this.loadingArea = false;
            let area = [];
            let areas = {};
            if (res.data) {
                for (let row of res.data) {
                    if (row.level === 3) {
                        row.leaf = true;
                    }
                    if (row.level === 0) {
                        area.push(row)
                    }
                    if (areas[row.parentCode]) {
                        areas[row.parentCode].push(row)
                    } else {
                        areas[row.parentCode] = [row]
                    }
                }
                this.leftAreaDataAll = areas
            }
            area.unshift({ name: "全部", areaCode: "", leaf: true })
            this.leftAreaData = area;
        },
        /**@method 获取地区 */
        loadAreaFun(node, resolve) {
            if (node.data.length === 0) {
                this.getParkManageAndTbArea();
            } else {
                resolve(this.leftAreaDataAll[node.data.areaCode])
            }
        },
        /**@method 获取物业公司数据 */
        async getCompanyAllInfo(companyName) {
            this.loadingProperty = true;
            let res = await getCompanyAllInfo({ pageNum: 1, pageSize: 30, companyName })
            this.loadingProperty = false;
            if (res.code === 200) {
                this.propertyData = res.data
            }
        },
        closeFun() {
            this.parkImg = [];
        },
        /**@level 0=省/直辖市/自治区/特别行政区,1=市,2=区,3=镇,4=居委会
         * @parentCode 父标签
         * @method 获取地区数据
         */
        async getTbArea(level = 0, parentCode) {
            let params = {
                level: level,
                parentCode: parentCode
            };
            if (level === 0) {
                this.cityData = [];
                this.areaData = [];
                this.streetData = [];
                this.province = "";//省
                this.city = "";//市
                this.area = "";//区
                this.ruleForm.areaCode = "";//街道
            }
            if (level === 1) {
                this.areaData = [];
                this.streetData = [];
                this.city = "";//市
                this.area = "";//区
                this.ruleForm.areaCode = "";//街道
            }
            if (level === 2) {
                this.streetData = [];
                this.area = "";//区
                this.ruleForm.areaCode = "";//街道
            }
            if (level === 3) {
                this.ruleForm.areaCode = "";//街道
            }
            if (!parentCode && level > 0) {
                return;
            }
            let res = await getTbArea(params);
            if (level === 0) {
                this.provinceData = res.data;
            }
            if (level === 1) {
                this.cityData = res.data;
            }
            if (level === 2) {
                this.areaData = res.data;
            }
            if (level === 3) {
                this.streetData = res.data;
            }
        },
        /**@level 0=省/直辖市/自治区/特别行政区,1=市,2=区,3=镇,4=居委会
         * @parentCode 父标签
         * @method 获取地区数据
         */
        async getAreaData(level = 0, parentCode) {
            let params = {
                level: level,
                parentCode: parentCode
            };
            let res = await getTbArea(params);
            if (level === 0) {
                this.provinceData = res.data;
            }
            if (level === 1) {
                this.cityData = res.data;
            }
            if (level === 2) {
                this.areaData = res.data;
            }
            if (level === 3) {
                this.streetData = res.data;
            }
        },
        /**@method 显示地图 */
        showMapFun() {
            this.showMap = true;
        },
        lngLatChange(lnglat) {
            //-console.log(lnglat);
            this.ruleForm.lng = lnglat.lng;
            this.ruleForm.lat = lnglat.lat;
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增园区";
            this.formType = "add";
            this.ruleForm = {
                companyId: this.userInfo.companyId,
                propertyId: "",
                parkName: "",
                linkman: "",
                linkmanPhone: "",
                linkmanQq: "",
                linkmanWx: "",
                parkImg: "",
                parkIntroduce: "",
                areaCode: "",
                address: "",
                lng: 0,
                lat: 0,
                parkLevel: "",
                industry: "",
                buildArea: "",
                parkArea: "",
                isTop: 0,
            }
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        async showUpdateModel(val) {
            this.showModel = true;
            this.modelTitle = "编辑园区";
            this.formType = "update";
            this.updateId = val.parkId;
            this.ruleForm = {
                companyId: this.userInfo.companyId,
                propertyId: val.propertyId,
                parkName: val.parkName,
                linkman: val.linkman,
                linkmanPhone: val.linkmanPhone,
                linkmanQq: val.linkmanQq,
                linkmanWx: val.linkmanWx,
                parkImg: val.parkImg,
                parkIntroduce: val.parkIntroduce,
                areaCode: val.areaCode,
                address: val.address,
                lng: val.lng,
                lat: val.lat,
                parkLevel: val.parkLevel,
                industry: val.industry,
                buildArea: val.buildArea,
                parkArea: val.parkArea,
                isTop: val.isTop,
            }
            this.getCompanyAllInfo(val.propertyName);
            this.parkImg = [];
            if (val.areaCode) {//初始化的时候生成地区数据
                let res = await getParentByCode({ areaCode: val.areaCode })
                if (res.data) {
                    for (let row of res.data) {
                        switch (row.level) {
                            case 0:
                                this.getAreaData(row.level + 1, row.areaCode);
                                this.province = row.areaCode;
                                break;
                            case 1:
                                this.getAreaData(row.level + 1, row.areaCode);
                                this.city = row.areaCode;
                                break;
                            case 2:
                                this.getAreaData(row.level + 1, row.areaCode);
                                this.area = row.areaCode;
                                break;
                            case 3:
                                this.getAreaData(row.level + 1, row.areaCode);
                                this.ruleForm.areaCode = row.areaCode;
                                break;
                        }
                    }
                }
            }
            if (val.parkImg) {
                this.parkImg = [{ url: val.parkImg }];
            }
        },
        /**@method 删除图片 */
        linkmanImgRemove(file, fileList) {
            //-console.log(file, fileList);
            this.ruleForm.parkImg = "";
            this.parkImg = [];
        },
        /**@method 图片上传 */
        linkmanImgProgress(event, file, fileList) {

        },
        /**@method 上传回调 */
        linkmanImgSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.ruleForm.parkImg = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
        },
        /**@method 更新全局园区数据 */
        async updatePark() {
            const park = sessionStorage.getItem('checkedPark');
            if (park) {
                this.$store.commit("setCheckedPark", JSON.parse(park))
            }
            let res = await getParkManageList({ isPage: 0 })
            let parkData = res.data.pageInfo.list ? res.data.pageInfo.list : [];
            if (parkData.length > 0) {
                this.$store.commit("setMyParkData", parkData)
                if (!park) {
                    sessionStorage.setItem("checkedPark", JSON.stringify(parkData[0]));
                    this.$store.commit("setCheckedPark", parkData[0]);
                }
            }
        },
        /**提交内容 */
        submitForm(payload) {
            // if (!this.ruleForm.parkImg) {
            //     return this.$message.error('请上传文章封面')
            // }
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    if (!this.ruleForm.areaCode) {
                        this.$message({
                            message: "地区请选择到镇/街道",
                            type: "warning"
                        })
                        return;
                    }
                    let params = {
                        ...this.ruleForm,
                        parkName: this.ruleForm.parkName.replace("-", "_")
                    };
                    let message = "";
                    if (this.formType === 'update') {
                        message = "编辑成功"
                        params.parkId = this.updateId;
                    } else {
                        message = "新增成功"
                    }
                    await setParkManage(params)
                    this.$message({
                        message: message,
                        type: "success"
                    });
                    this.showModel = false;
                    this.searchData = { name: "", areaCode: "" };
                    this.checkedAreaArr = ["全部"];
                    this.updatePark();
                    this.getParkManageAndTbArea();
                    this.getList();
                } else {
                    //-console.log("error submit!!");
                    return false;
                }
            });
        },
        /**@method 搜索 */
        searchFun() {
            this.currentPage = 1
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                companyId: this.userInfo.companyId
            };
            if (this.searchData.name) {
                params.parkName = this.searchData.name
            }
            if (this.searchData.areaCode && this.searchData.areaCode != "all") {
                params.areaCode = this.searchData.areaCode
            }
            try {
                this.loading = true;
                let res = await getParkManageList(params);
                this.loading = false;
                const { data } = res;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.yqContent {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.leftArea {
    width: 300px;
    max-height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
}

.Illusion {
    position: absolute;
    height: 100%;
    z-index: 2;
}

.zoom {
    background: #f7fafa;
    cursor: e-resize;
    width: 10px;
    border-right: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
}

.rightTable {
    width: calc(100% - 310px);
    overflow-x: hidden;
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>