import httpUtil from "@/utils/httpUtil";

/**获取园区管理列表 */
export const getParkManageList = async params => httpUtil.post("/api/crmPc/property/getParkManageList", params)

/**新增编辑园区 */
export const setParkManage = async params => httpUtil.post("/api/crmPc/property/setParkManage", params)

/** 获取地区数据 */
export const getTbArea = async params => httpUtil.post("/api/crmPc/tbArea/getTbArea", params)

/** 通过地区码获取父级 */
export const getParentByCode = async params => httpUtil.post("/api/crmPc/tbArea/getParentByCode", params)

/** 园区服务 */
export const getParkMessage = async params => httpUtil.post("/api/crmPc/property/getParkMessageList", params)

/** 新增编辑园区服务 */
export const setParkMessage = async params => httpUtil.post("/api/crmPc/property/setParkMessage", params)

/**设置园区动态 */
export const delParkMessage = (params) => httpUtil.post("/api/crmPc/property/delParkMessage", params)

/**动态分类列表 */
export const getContentTypeList = (params) => httpUtil.post("/api/crmPc/sysDict/getContentTypeList", params)

/**设置动态分类 */
export const setContentType = (params) => httpUtil.post("/api/crmPc/sysDict/setContentType", params)

/** 获取公司列表 */
export const getCompanyAllInfo = async params => httpUtil.post("/api/crmPc/companyGoods/getCompanyAllInfo", params)

/**获取有园区的地区 */
export const getParkManageAndTbArea = (params) => httpUtil.post("/api/crmPc/property/getParkManageAndTbArea", params)

/**字典 */
export const getByDictType = async params => httpUtil.post("/api/crmPc/sysDict/getByDictType", params);

/**进驻分类 */
export const getParkShopTypeLinkList = async params => httpUtil.post("/api/crmPc/property/getParkShopTypeLinkList", params);

/**新增关联分类 */
export const insertParkShopTypeLink = async params => httpUtil.post("/api/crmPc/property/insertParkShopTypeLink", params);

/**修改关联分类 */
export const updateParkShopTypeLink = async params => httpUtil.post("/api/crmPc/property/updateParkShopTypeLink", params);

/**关联园区和公司 */
export const linkParkAndCompany = async params => httpUtil.post("/api/crmPc/property/linkParkAndCompany", params);

/**传parkId获取关联园区下的公司
 * 传companyId获取我关联的园区
 */
export const getParkCompanyLink = async params => httpUtil.post("/api/crmPc/property/getParkCompanyLink", params);

/**退出园区
 * 踢出园区
 */
export const delParkCompanyLink = async params => httpUtil.post("/api/crmPc/property/delParkCompanyLink", params);

/**续费 */
export const setHouseManageCompanyEndTime = async params => httpUtil.post("/api/crmPc/property/setHouseManageCompanyEndTime", params);

/**获取园区关联名片的列表 */
export const getParkCardShareLinkList = (params) => httpUtil.post("/api/crmPc/property/getParkCardShareLinkList", params)

/**取消关联名片的列表 */
export const delParkCardShareLink = (params) => httpUtil.post("/api/crmPc/property/delParkCardShareLink", params)

/** 获取广告分类 */
export function fetchPhotoType(params) {
    return httpUtil.post("/api/crmPc/companyPhoto/selectTypeNum", params)
}

/** 获取广告 */
export function selectCompanyPhoto(params) {
    return httpUtil.post("/api/crmPc/companyPhoto/selectCompanyPhoto", params)
}

/** 新增/编辑广告 */
export function insertCompanyPhoto(params) {
    return httpUtil.post("/api/crmPc/companyPhoto/insertCompanyPhoto", params)
}

/**获得关联园区的商品信息 */
export const getGoodsListByLink = params => { params.accountType = 8; return httpUtil.post("/api/crmPc/member/getGoodsListByLink", params) };

/**活动订单 */
export function getActivityRegisterOrderList(params) {
    return httpUtil.post("/api/crmPc/activityEvent/getActivityRegisterOrderList", params)
}

/**退款 */
export function updateRefundState(params) {
    params.refundState = 3;
    return httpUtil.post("/api/crmPc/activityEvent/updateRefundState", params)
}